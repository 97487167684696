import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"
import Layout from "../components/layout"
// import SEO from "../components/seo"
import "../assets/sass/about-us.sass"


const About = () => (

    <div className="info-page about-us-container">
        <span class="kevin-landscaping">Kevin's Fencing and Landscaping</span>
        <div class="about-us-bio">
        <p>
            We're a family owned landscaping company offering a wide variety of residential and commercial landscaping services.
            Our great team consist of professional landscapers and designers with years of experience.
            Our portfolio is filled with 11 years of past work designing and installing home improvements, we like attention to detail and getting the work done accurately.
            Set up an appointment to meet with us.
        </p>

        <p>
            We’re always excited to start something new, and improving your home. Give us a
            call today at  <Link class="contact-links" href="tel:630-664-3463">630-664-3463</Link> for more details on how we can start your new project.
        </p>
        <p>
            We're Licenced and insured.
        </p>
    </div>    

</div>

)

export default About